import { MenuItemType } from "components";
import * as hash from "object-hash";

export type BasketItem = MenuItemType & {
  quantity: number;
  selectedOptions?: string[];
};

export type BasketItemHashed = BasketItem & {
  hash: string;
};

export type BasketState = {
  items: BasketItemHashed[];
};

const defaultBasketState: BasketState = {
  items: [],
};

export enum BasketAction {
  ADD = "BASKET_ADD",
  REMOVE = "BASKET_REMOVE",
  RESET = "BASKET_RESET",
}

type BasketAddAction = {
  type: BasketAction.ADD;
  item: BasketItem;
};

type BasketRemoveAction = {
  type: BasketAction.REMOVE;
  hash: string;
};

type BasketReset = {
  type: BasketAction.RESET;
};

export const removeItemFromBasket = (hash: string): BasketRemoveAction => ({
  type: BasketAction.REMOVE,
  hash,
});

export const basketReducer = (
  state: BasketState = defaultBasketState,
  action: BasketAddAction | BasketRemoveAction | BasketReset
): BasketState => {
  switch (action.type) {
    case BasketAction.ADD:
      const existingItem = state.items
        ? state.items.find(
            basketItem => basketItem.hash === generateHash(action.item)
          )
        : null;
      if (existingItem) {
        return {
          items: [
            ...state.items.filter(
              basketItem => basketItem.hash !== existingItem.hash
            ),
            {
              ...existingItem,
              quantity: existingItem.quantity + 1,
            },
          ],
        };
      }
      return {
        items: [
          ...(state.items || []),
          generateBasketItemHash({
            ...action.item,
            quantity: 1,
          }),
        ],
      };
    case BasketAction.REMOVE:
      const removeItem = state.items
        ? state.items.find(basketItem => basketItem.hash === action.hash)
        : null;
      if (!removeItem) {
        return state;
      }

      return {
        items: [
          ...(state.items.filter(
            basketItem => basketItem.name !== removeItem.name
          ) || []),
        ],
      };
    case BasketAction.RESET:
      return {
        items: [],
      };
    default:
      return state;
  }
};

const generateBasketItemHash = (item: BasketItem): BasketItemHashed => ({
  ...item,
  hash: generateHash(item),
});

const generateHash = (item: BasketItem): string =>
  hash.MD5({ name: item.name, selectedOptions: item.selectedOptions });
