import { MenuItemType } from "components";

export type OptionModalState = {
  item?: MenuItemType;
};

export enum OptionModalActions {
  OPEN = "open",
  CLOSE = "close",
}

type OpenModalAction = {
  type: OptionModalActions.OPEN;
  item: MenuItemType;
};

type CloseModalAction = {
  type: OptionModalActions.CLOSE;
};

const defaultOptionModalState = {};

export const optionModalReducer = (
  state: OptionModalState = defaultOptionModalState,
  action: OpenModalAction | CloseModalAction
): OptionModalState => {
  switch (action.type) {
    case OptionModalActions.CLOSE:
      return {
        item: undefined,
      };
    case OptionModalActions.OPEN:
      return {
        item: action.item,
      };
    default:
      return state;
  }
};
