import { createStore } from "redux";
import { basketReducer } from "./basket";
import { optionModalReducer } from "./option.modal";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

export default preloadedState => {
  return createStore(
    combineReducers({
      basket: basketReducer,
      optionModal: optionModalReducer,
    }),
    composeWithDevTools()
  );
};
